<!--上传图片-->
<template>
    <div v-loading="uploading">
        <el-upload
                :action="serverUrl + fileApi.upload"
                list-type="picture-card"
                :limit="count"
                :file-list="fileList"
                :multiple="false"
                name="file"
                :class="{'upload-hidden': fileList.length >= count}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload"
                :on-change="handleChange"
                :on-success="handleSuccess"
                :on-error="handleError"
                :headers="headers"
                :data="{uploadType: upload_type}"
        >
            <!--            :http-request="handleUpload"-->
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>

</template>

<script>
    import Vue from 'vue';
    import {Dialog, Upload} from 'element-ui';
    import app from '../utils/app.js';
    import {fileApi} from '../api/api';

    Vue.use(Dialog);
    Vue.use(Upload);
    export default {
        name: "UploadImage",
        props: {
            // 上传图片的大小限制
            size: {
                type: Number,
                default: 4
            },
            // 上传图片的数量限制
            count: {
                type: Number,
                default: 1
            },
            // 存放上传成功返回给父组件的图片列表
            list: {
                type: Array,
                default() {
                    return [];
                }
            },
            upload_type: {
                type: String,
                default: 'image'
            }
        },
        data() {
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                fileList: [], // 存放显示在页面的图片列表
                uploading: false, // 正在上传的loading
                serverUrl: app.serverUrl,
                headers: {
                    token: app.getToken(),
                },
                fileApi: fileApi,
            };
        },
        methods: {
            // 删除图片
            handleRemove(file) {
                let {fileList, list} = this;
                const ind = fileList.findIndex(f => (f.uid === file.uid));
                fileList.splice(ind, 1);
                this.fileList = fileList;
                list.splice(ind, 1);
                this.$emit('change', list);
            },
            // 预览
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            // 改变
            handleChange(file, fileList) {
                this.fileList = fileList || [];
            },
            // 成功
            handleSuccess(res, file) {
                this.uploading = false;
                switch (res.code) {
                    case -1:
                        app.alert('登录过期，请重新登录~', 'error');
                        const s_t = setTimeout(() => {
                            clearTimeout(s_t);
                            window.location.href = '/login';
                        }, 2000);
                        break;
                    case 1:
                        app.alert('上传成功', 'success');
                        const {fileList} = this;
                        let {list} = this;
                        for (let f = 0; f < fileList.length; f++) {
                            const f_item = fileList[f];
                            if (file.uid === f_item.uid) {
                                list.push({
                                    name: f_item.name,
                                    uid: f_item.uid,
                                    req_url: res.data.file_path, // 发送给后端的url
                                    url: this.serverUrl + res.data.file_path, // 用于在页面上展示的url
                                });
                                break;
                            }
                        }
                        this.$emit('change', list);
                        break;
                    default:
                        app.alert('上传失败，请重试~', 'error');
                }
            },
            // 失败
            handleError() {
                this.uploading = false;
                app.alert('上传失败', 'error');
            },
            // 上传之前
            beforeAvatarUpload(file) {
                const types = ['image/jpeg', 'image/jpg', 'image/png'];
                const {size} = this;
                const size_max = file.size / 1024 / 1024 > size;
                if (types.indexOf(file.type) === -1) {
                    app.alert('图片只能是 jpg、png 格式!', 'error');
                    return false;
                }
                if (size_max) {
                    app.alert(`图片大小不能超过 ${size}MB!`, 'error');
                    return false;
                }
                this.uploading = true;
                return true;
            },
            // 初始化时设置初始图片
            setFileList() {
                this.fileList = this.list;
                console.log(this.list)
            },
            handleUpload(e) {
                console.log(e)
            }
        },
        beforeDestroy() {
            this.fileList = []
        },
    }
</script>

<style scoped>
</style>
<!--我的资料，收藏，回复等-->
<template>
    <div class="my">
        <el-tabs v-model="activeName" :tab-position="tabPosition" @tab-click="handleTab"
                 :style="{marginTop: tabPosition === 'top' ? 0 : '20px'}">
            <el-tab-pane label="分类" name="type">
                <type-list :position="tabPosition"></type-list>
            </el-tab-pane>
            <el-tab-pane label="轮播图" name="carousel">
                <carousel-list :position="tabPosition" ref="ref_carousel"></carousel-list>
            </el-tab-pane>
            <el-tab-pane label="回收站" name="waste">
                <waste-list :position="tabPosition" ref="ref_waste"></waste-list>
            </el-tab-pane>
            <el-tab-pane label="设置" name="setting">
                <setting ref="setting"></setting>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Tabs, TabPane} from 'element-ui';

    Vue.use(Tabs);
    Vue.use(TabPane);
    import typeList from './TypeList.vue';
    import carouselList from './CarouselList.vue';
    import wasteList from './WasteList.vue';
    import setting from './Setting.vue';
    import app from '../../utils/app';

    export default {
        name: "Admin",
        components: {
            typeList,
            carouselList,
            wasteList,
            setting
        },
        computed: {
            // tabs所在位置，屏幕小于500就在上面
            tabPosition() {
                const w = window.screen.width;
                return w < 500 ? 'top' : 'left'
            }
        },
        data() {
            return {
                activeName: 'type',
                infoData: {}, // 个人资料的数据
            }
        },
        mounted() {

        },
        methods:{
            // 点击tabs
            handleTab(e){
                switch (e.name) {
                    case 'carousel':
                        this.$refs.ref_carousel.getAdData();
                        break;
                    case 'setting':
                        this.$refs.setting.getList();
                        break;
                    case 'waste':
                        this.$refs.ref_waste.getList();
                        break;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
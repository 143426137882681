<!--分类管理-->
<template>
    <div>
        <div class="flex align-center justify-between type-title">
            <el-select v-model="search_type" placeholder="类型筛选" size="small" clearable @change="handleSearch"
                       class="search-select" :style="{marginLeft: position === 'left' ? '50px' : 0}">
                <el-option v-for="(b_item, b_index) in bugTList" :key="b_index"
                           :label="b_item.name"
                           :value="b_item.value">
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="updateVisible = true">新增分类</el-button>
        </div>
        <div v-loading="listLoading">
            <el-table
                    :data="dataList"
                    stripe
                    row-key="id"
                    style="width: 100%">
                <el-table-column
                        prop="type_name"
                        label="名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="bug_type"
                        label="类型"
                        align="center"
                        width="100">
                    <template slot-scope="scope">
                        <span>{{getBugTName(scope.row.bug_type)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="user_name"
                        label="创建人"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="bug_type"
                        label="状态"
                        align="center"
                        width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.type_status === '1' ? '启用' : '停用'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="date"
                        label="操作"
                        align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" :style="{color: color}" @click="showEditModal(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="text" size="small" :style="{color: color_meimei}"
                                   @click="handleDel(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--        分页-->
            <div class="text-right" :style="{padding: `${pageCss.padding || 20}px 0`}">
                <el-pagination
                        :small="pageCss.small || false"
                        :pager-count="pageCss.pager_count || 9"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="size"
                        :current-page.sync="page"
                        @current-change="getList"
                >
                </el-pagination>
            </div>

            <!--新增/编辑的弹框-->
            <el-dialog
                    :title="updateData.type_id ? '编辑分类':'新增分类'"
                    :visible.sync="updateVisible"
                    :width="position === 'left' ? '500px' : '90%'"
                    @close="handleCloseUpdate">
                <el-form ref="typeForm" :model="updateData" :rules="rulesType" label-width="60px"
                         class="type-form">
                    <el-form-item prop="type_name" label="名称">
                        <el-input v-model="updateData.type_name" placeholder="请输入分类名称" :maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item prop="type_type" label="类型">
                        <el-select v-model="updateData.bug_type" placeholder="请选择分类类型" style="width: 100%;">
                            <el-option v-for="(b_item, b_index) in bugTList" :key="b_index"
                                       :label="b_item.name"
                                       :value="b_item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="sort" label="排序">
                        <el-input-number v-model="updateData.sort" placeholder="请输入排序(数字越小排越前)" :min="0"
                                         style="width: 100%;"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="sort" label="状态" v-show="updateData.type_id">
                        <el-switch
                                v-model="updateData.type_status"
                                active-value="1"
                                inactive-value="2"
                                :active-color="color"
                                inactive-color="#ddd"
                                :width="34"
                        ></el-switch>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleCloseUpdate" size="small">取 消</el-button>
                    <el-button type="primary" @click="handleConfirmUpdate" size="small" :loading="updateLoading">确 定
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Dialog, InputNumber, MessageBox, Switch} from 'element-ui';
    import app from '../../utils/app.js';
    import {typeApi} from "../../api/api";

    Vue.use(Dialog);
    Vue.use(InputNumber);
    Vue.use(Switch);

    export default {
        name: "MyList",
        props: {
            // 根据父组件的tabs位置做自适应
            position: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                color: app.color,
                color_meimei: app.color_meiemi,
                dataList: [],
                listLoading: false, // 表格loading
                bugTList: app.bugTList,
                search_type: '', // 搜索的类型，1 bug 2 问题
                updateVisible: false, // 新增/编辑的弹框
                updateData: {}, // 编辑中的数据，根据是否有id来判断新增还是编辑
                rulesType: {
                    type_name: [
                        {required: true, message: '请输入分类名称', trigger: 'blur'}
                    ],
                    bug_type: [
                        {required: true, message: '请选择分类类型', trigger: 'change'}
                    ],
                },
                updateLoading: false,
                page: 1,
                size: 10,
                total: 0,
            }
        },
        computed: {
            // 分页页码相关,自适应兼容处理
            pageCss() {
                return this.$store.state.pageCss;
            }
        },
        methods: {
            // 监听关闭弹框
            handleCloseUpdate() {
                this.updateData = {};
                this.$refs['typeForm'].resetFields();
                this.updateVisible = false;
            },
            // 弹框点击确定
            handleConfirmUpdate() {
                this.$refs['typeForm'].validate(async (valid) => {
                    if (valid) {
                        this.updateLoading = true;
                        const {updateData} = this;
                        let params = {
                            ...updateData,
                        }
                        const req = await app.ajax(updateData.type_id ? typeApi.updateType : typeApi.addType, params);
                        this.updateLoading = false;
                        if (req.code === 1) {
                            app.alert(`${updateData.type_id ? '修改' : '添加'}成功`, 'success');
                            this.handleCloseUpdate();
                            await this.getList();
                        } else {
                            app.alert(req.msg, 'error');
                        }

                    }
                });
            },
            // 查询列表
            async getList() {
                this.listLoading = true;
                const {search_type, page, size} = this;
                const params = {
                    bug_type: search_type,
                    page,
                    size
                }
                const req = await app.ajax(typeApi.searchType, params);
                this.listLoading = false;
                this.total = req.total || 0;
                if (req.code === 1) {
                    this.dataList = req.data || [];
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 获取type名字
            getBugTName(ty) {
                const {bugTList} = this;
                let text = '';
                for (let b = 0; b < bugTList.length; b++) {
                    if (bugTList[b].value === ty) {
                        text = bugTList[b].name;
                        break;
                    }
                }
                return text;
            },
            handleSearch() {
                this.page = 1;
                this.getList();
            },
            //打开编辑框
            showEditModal(item) {
                this.updateVisible = true;
                this.updateData = {...item};
            },
            // 删除其中一个
            handleDel(item) {
                MessageBox.confirm('是否确定删除该分类?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '删除中...';
                            const req = await app.ajax(typeApi.delType, {type_id: item.type_id});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert(`删除成功`, 'success');
                                const {page, dataList} = this;
                                dataList.length === 1 && (this.page = (page === 1) ? 1 : (page - 1));
                                await this.getList();
                            } else {
                                app.alert(req.msg, 'error');
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });
            },
        },
        mounted() {
            this.getList();
        }
    }
</script>

<style lang="scss" scoped>
    .type-title {
        margin-bottom: 20px;

        .search-select {
            width: 120px;
        }
    }

    .type-form {
        padding: 0 20px;
    }
</style>
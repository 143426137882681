<!--轮播图管理-->
<template>
    <div>
        <div class="flex align-center type-title">
            <el-button type="primary" size="small" @click="updateVisible = true">新增轮播图</el-button>
        </div>
        <div class="no-data text-center" v-show="!listLoading && dataList.length === 0">暂无数据</div>
        <div v-loading="listLoading" class="flex c-list-box">
            <div class="c-list flex" v-for="item in dataList" :key="item.ad_id">
                <img :src="serverUrl + item.ad_img" alt="" class="c-img"/>
                <div class="c-info flex flex-column justify-around align-center">
                    <span>类型：{{item.ad_type === '1' ? '链接' : '无'}}</span>
                    <el-link type="primary" :href="item.ad_url" target="_blank" v-if="item.ad_type === '1'">
                        {{item.ad_url}}
                    </el-link>
                    <span>修改人：{{item.user_name}}</span>
                    <span>{{item.update_time}}</span>
                    <span>点击数：{{item.hits}}</span>
                    <el-button type="text" size="small" :style="{color: color}" @click="showEditModal(item)">编辑
                    </el-button>
                    <el-button type="text" size="small" :style="{color: color_meimei, marginLeft: 0}"
                               @click="handleDel(item)">删除
                    </el-button>
                </div>
            </div>
        </div>

        <!--新增/编辑的弹框-->
        <el-dialog
                :title="updateData.ad_id ? '编辑轮播图':'新增轮播图'"
                :visible.sync="updateVisible"
                :width="position === 'left' ? '400px' : '90%'"
                @close="handleCloseUpdate">
            <el-form ref="carouselForm" :model="updateData" :rules="rules" label-width="60px"
                     class="type-form">
                <el-form-item prop="img_list" label="图片">
                    <upload-image @change="uploadChange" :list.sync="imgList" upload_type="ad" :count="1"
                                  ref="uploadImg"></upload-image>
                    <div class="size-s">建议上传图片宽高比为 3:10</div>
                </el-form-item>
                <el-form-item prop="ad_type" label="类型">
                    <el-select v-model="updateData.ad_type" placeholder="请选择类型" style="width: 100%;">
                        <el-option
                                label="链接"
                                value="1">
                        </el-option>
                        <el-option
                                label="无"
                                value="0">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="ad_url" label="链接" v-if="updateData.ad_type === '1'">
                    <el-input v-model="updateData.ad_url" placeholder="请填写链接"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCloseUpdate" size="small">取 消</el-button>
                <el-button type="primary" @click="handleConfirmUpdate" size="small" :loading="updateLoading">确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Dialog, Link, MessageBox} from 'element-ui';
    import app from '../../utils/app.js';
    import uploadImage from '../../components/UploadImage';
    import {ad} from '../../api/api';

    Vue.use(Dialog);
    Vue.use(Link);
    export default {
        name: "CarouselList",
        components: {
            uploadImage
        },
        props: {
            // 根据父组件的tabs位置做自适应
            position: {
                type: String,
                default: 'left'
            }
        },
        data() {
            // 判断图片必填
            const validateImage = (rule, value, callback) => {
                if (this.imgList.length === 0) {
                    callback(new Error('请上传图片'));
                } else {
                    callback();
                }
            };
            return {
                listLoading: false,
                color: app.color,
                serverUrl: app.serverUrl,
                color_meimei: app.color_meiemi,
                updateVisible: false, // 新增/编辑的弹框
                updateData: {}, // 编辑中的数据，根据是否有id来判断新增还是编辑
                rules: {
                    img_list: [
                        {required: true, validator: validateImage}
                    ],
                    ad_type: [
                        {required: true, message: '请选择类型', trigger: 'change'}
                    ],
                    ad_url: [
                        {required: true, message: '请填写链接', trigger: 'blur'}
                    ],
                },
                updateLoading: false,
                imgList: [], // 新增/编辑里的图片列表
                dataList: [],
            }
        },
        methods: {
            // 监听关闭弹框
            handleCloseUpdate() {
                this.updateData = {};
                this.imgList = [];
                this.$refs['carouselForm'].resetFields();
                this.$nextTick(() => {
                    this.$refs['uploadImg'].setFileList();
                });
                this.updateVisible = false;
            },
            // 弹框点击确定
            handleConfirmUpdate() {
                this.$refs['carouselForm'].validate(async (valid) => {
                    if (valid) {
                        this.updateLoading = true;
                        const {updateData, imgList} = this;
                        let params = {
                            ...updateData,
                            ad_img: imgList[0].req_url
                        }
                        const req = await app.ajax(updateData.ad_id ? ad.updateAd : ad.addAd, params);
                        this.updateLoading = false;
                        if (req.code === 1) {
                            app.alert(`${updateData.ad_id ? '修改' : '添加'}成功`, 'success');
                            this.handleCloseUpdate();
                            await this.getAdData();
                        } else {
                            app.alert(req.msg, 'error');
                        }
                    }
                });
            },
            // 上传图片成功/删除
            uploadChange(list) {
                this.imgList = list;
            },

            // 查询轮播图
            async getAdData() {
                this.listLoading = true;
                const req = await app.ajax(ad.searchAd);
                this.listLoading = false;
                if (req.code === 1) {
                    this.dataList = req.data;
                } else {
                    app.alert(req.msg, 'error')
                }
            },
            //打开编辑框
            showEditModal(item) {
                this.updateVisible = true;
                this.updateData = {...item};
                const {serverUrl} = this;
                this.$nextTick(() => {
                    this.imgList = item.ad_img ? [{
                        uid: new Date().getTime(),
                        url: serverUrl + item.ad_img,
                        req_url: item.ad_img,
                        name: '图片'
                    }] : [];
                    this.$nextTick(() => {
                        this.$refs.uploadImg.setFileList();
                    })
                })
            },
            // 删除其中一个
            handleDel(item) {
                MessageBox.confirm('是否确定删除该轮播图?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '删除中...';
                            const req = await app.ajax(ad.delAd, {ad_id: item.ad_id});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert(`删除成功`, 'success');
                                await this.getAdData();
                            } else {
                                app.alert(req.msg, 'error');
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });

            }
        }
    }
</script>

<style lang="scss" scoped>
    .type-title {
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .c-list-box {
        flex-wrap: wrap;
        min-height: 500px;

        .c-list {
            width: 300px;
            height: 500px;
            border-radius: 10px;
            box-shadow: 0 0 10px 5px #eee;
            margin: 25px;

            .c-img {
                width: 150px;
                height: 100%;
            }

            .c-info {
                width: 150px;
                height: 100%;
            }
        }
    }
</style>
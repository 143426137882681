<!--回收站管理-->
<template>

    <div>
        <div class="flex align-center type-title">
            <el-input
                    clearable
                    placeholder="搜内容/作者"
                    v-model="searchData.keyword"
                    class="search-input"
                    size="small"
                    :style="{marginLeft: position === 'left' ? '50px' : 0}"
                    @keyup.enter.native="handleSearch"
            >
                <i slot="suffix" class="el-input__icon el-icon-search cursor-hand" @click="handleSearch"></i>
            </el-input>
            <el-select v-model="searchData.waste_type" placeholder="类型筛选" size="small" @change="handleSearch"
                       class="search-select">
                <el-option label="文章" value="1"></el-option>
                <el-option label="回复" value="2"></el-option>
            </el-select>
        </div>
        <div v-loading="listLoading">
            <el-table
                    :data="dataList"
                    stripe
                    row-key="id"
                    style="width: 100%">
                <el-table-column
                        prop="content"
                        label="内容"
                        align="center">
                    <template slot-scope="scope">
                        <div>
                            <span class="mar-rig">{{(scope.row.content && scope.row.content.length > 20) ? (scope.row.content.substr(0,20) + '...') : scope.row.content}}</span>
                            <span class="color-theme cursor-hand" @click="toDetail(scope.row.bug_id)" v-if="searchData.waste_type === '1'">查看原贴</span>
                            <span class="color-theme cursor-hand" @click="showDetail(scope.row.content)" v-else-if="(scope.row.content && scope.row.content.length > 20)">全部</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="user_name"
                        label="作者"
                        align="center">
                    <template slot-scope="scope">
                        <span :class="{'color-gray-more': !scope.row.user_name}">{{scope.row.user_name || '游客'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="update_user_name"
                        label="删除人"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="删除时间"
                        align="center"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="date"
                        label="操作"
                        align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" :style="{color: color}" @click="handle(1, scope.row)">恢复</el-button>
                        <el-button type="text" size="small" :style="{color: color_meimei}" @click="handle(2, scope.row)">永久删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--        分页-->
            <div class="text-right" :style="{padding: `${pageCss.padding || 20}px 0`}">
                <el-pagination
                        :small="pageCss.small || false"
                        :pager-count="pageCss.pager_count || 9"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="size"
                        :current-page.sync="page"
                        @current-change="getList"
                >
                </el-pagination>
            </div>
        </div>

        <!--详情弹框-->
        <el-dialog
                title="详细"
                :visible.sync="detailVisible"
                :width="position === 'left' ? '400px' : '90%'"
                @close="handleCloseModal">
            <div v-html="content"></div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCloseModal" size="small">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import app from '../../utils/app.js';
    import {bugApi, typeApi} from "../../api/api";
    import {MessageBox} from "element-ui";

    export default {
        name: "WasteList",
        data() {
            return {
                color: app.color,
                color_meimei: app.color_meiemi,
                dataList: [],
                listLoading: false, // 表格loading
                searchData: {
                    waste_type: "1"
                }, // 搜索条件
                detailVisible: false, // 详情弹框
                content: "", // 详情数据
                page: 1,
                size: 10,
                total: 0,
            }
        },
        props: {
            // 根据父组件的tabs位置做自适应
            position: {
                type: String,
                default: 'left'
            }
        },
        computed: {
            // 分页页码相关,自适应兼容处理
            pageCss() {
                return this.$store.state.pageCss;
            }
        },
        methods: {
            // 关闭详情弹框
            handleCloseModal() {
                this.detail = {};
                this.detailVisible = false;
            },
            // 打开详情弹框
            showDetail(content){
                this.content = content;
                this.detailVisible = true;
            },
            // 搜索
            handleSearch(){
                this.page = 1;
                this.getList();
            },
            // 查询列表
            async getList() {
                this.listLoading = true;
                const {searchData, page, size} = this;
                let params = {
                    page,
                    size,
                    ...searchData
                }
                const req = await app.ajax(bugApi.searchWasteList, params);
                this.listLoading = false;
                this.total = req.total || 0;
                if (req.code === 1) {
                    this.dataList = req.data || [];
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 调转到详情页
            toDetail(id){
                window.open(`/detail?id=${id}`)
            },
            // 操作
            // state 1 恢复 2 删除
            async handle(state,row){
                const text = state === 1 ? '恢复' : '永久删除';
                MessageBox.confirm(`是否确定${text}这条数据?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '处理中...';
                            const req = await app.ajax(bugApi.handleWaste, {...row, state: state + "", auth_id: row.user_id || ""});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert(`${text}成功`, 'success');
                                const {page, dataList} = this;
                                dataList.length === 1 && (this.page = (page === 1) ? 1 : (page - 1));
                                await this.getList();
                            } else {
                                app.alert(req.msg, 'error');
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .type-title {
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 50px;
        }
        .search-select {
            width: 120px;
        }
    }
</style>
<!--设置-->
<template>
    <div class="setting" v-loading="listLoading">
        <div class="setting-list flex align-center" v-for="item in dataList" :key="item.setting_id">
            <template v-if="item.setting_type === 'switch'">
                <div class="setting-label">{{item.setting_name}}</div>
                <div class="setting-value">
                    <el-switch
                            v-model="item.setting_value"
                            :active-value="'1'"
                            :inactive-value="'0'"
                            :active-color="color"
                            inactive-color="#ddd"
                            :width="34"
                            style="margin-right: 5px"
                            @change="handleSetting($event,item)"
                    >
                    </el-switch>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {Switch} from 'element-ui';
    import app from '../../utils/app';
    import {settingApi} from "../../api/api";

    Vue.use(Switch);
    export default {
        name: "Setting",
        data() {
            return {
                color: app.color,
                setting: {
                    visitor: 0,
                },
                listLoading: false,
                dataList: [],
            }
        },
        methods: {
            // 查询列表
            async getList() {
                this.listLoading = true;
                const req = await app.ajax(settingApi.searchSetting, {});
                this.listLoading = false;
                if (req.code === 1) {
                    this.dataList = req.data || [];
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 修改值
            async handleSetting(val, item) {
                this.listLoading = true;
                const params = {
                    setting_id: item.setting_id,
                    setting_value: val
                }
                const req = await app.ajax(settingApi.updateSetting, params);
                this.listLoading = false;
                if (req.code === 1) {
                    app.alert(req.msg, 'success');
                    await this.getList();
                } else {
                    app.alert(req.msg, 'error');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .setting {
        padding: 20px;

        .setting-list {
            margin-bottom: 20px;

            .setting-label {
                width: 120px;
                flex-shrink: 0;
            }

            .setting-value {
                flex-grow: 1;
            }
        }
    }
</style>